import React from "react"
import Banner from "../components/banner"
import Layout from "../components/layout"
import SEO from "../components/seo"
const contact = () => {
  return (
    <Layout>
      <SEO title="Contact" />
      <Banner hero="contact-banner" title="Contact" />

      <main className="container" id="content-contact">
        <div className="row">
          <div id="contact" className="col-md-6 col-sm-12">
            <h1>Contact Info</h1>
            <h2>Address </h2>
            <p>2063 Rt. 88 Brick, NJ. 08724</p>
            <h2>Phone Numbers</h2>
            <p>
              <a href="tel:732-892-9595">(732) 892-9595</a>
            </p>
            <p>
              <a href="tel:732-892-4569">(732) 892-4569</a>
            </p>
            <hr></hr>
            <h2>Opening Hours</h2>
            <table>
              <tbody>
                <tr>
                  <td>Monday:</td>
                  <td>4:00PM - 10:00PM</td>
                </tr>
                <tr>
                  <td>Tuesday:</td>
                  <td>11:00AM - 10:00PM</td>
                </tr>
                <tr>
                  <td>Wednesday:</td>
                  <td>11:00AM - 10:00PM</td>
                </tr>
                <tr>
                  <td>Thursday:</td>
                  <td>11:00AM - 10:00PM</td>
                </tr>
                <tr>
                  <td>Friday:</td>
                  <td>11:00AM - 10:30PM</td>
                </tr>
                <tr>
                  <td>Saturday:</td>
                  <td>11:00AM - 10:30PM</td>
                </tr>
                <tr>
                  <td>Sunday:</td>
                  <td>12:00AM - 10:00PM</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div id="map" className="col-md-6 col-sm-12">
            <h1>Map</h1>
            <div className="map-responsive">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3053.0082270895223!2d-74.10900218435862!3d40.07522858426128!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c1846c9bed9b05%3A0xef520bc64490b5e2!2s2063%20NJ-88%2C%20Brick%20Township%2C%20NJ%2008724!5e0!3m2!1sen!2sus!4v1613885911814!5m2!1sen!2sus"
                width="600"
                height="450"
                loading="lazy"
              ></iframe>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  )
}

export default contact
